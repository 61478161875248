import React from "react"
import HeaderOne from "../components/header-one"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import MenuContextProvider from "../context/menu-context"
import PricingOne from "../components/pricing-one"
import ProgressOne from "../components/progress-one"
import Footer from "../components/footer"

const PricingPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Pricing">
        <HeaderOne />
        <PageHeader title="Our Pricing" name="Pricing" />
        <PricingOne />
        <ProgressOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  )
}

export default PricingPage
